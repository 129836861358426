import React,{ useEffect, useState }from 'react'
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';
import { Col, Form, Button } from 'react-bootstrap';
import { Row,Container } from 'react-bootstrap';
import { getNewPartnerToken, getNewToken } from '../../config/cookie.config';

const ModifyFund = () => {
    const location = useLocation()
    let navigate = useNavigate();
    const [showReduceModal, setshowReduceModal] = useState(false);
    const [showConfirmModal,setShowConfirmModal] = useState(false);
    const [startCustomerClick, setStartCustomerClick] = useState(false);
    const [startPartnerClick, setStartPartnerClick] = useState(false);
    const [selectedPartnerValidate, setselectedPartnerValidate] = useState('default');
    const [selectedCustomerValidate, setselectedCustomerValidate] = useState('default');
    const [customerName, setCustomerName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerAmount, setCustomerAmount] = useState('');
    const [getbusinessCustomer, setbusinessCustomer] = useState([]);
    const [reduces, setReduces] = useState(true);
    const [isChecked, setIsChecked] = useState(true); 
    const [fundValue, setFundValue] = useState('');
    const [startFundValidation, setStartFundValidation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allValidation, setAllValidation] = useState(0);
    const [sendPartnerID, setSendPartnerID] = useState('');
    const [cusName,setCusName]= useState('');
  const [cusEmail,setCusEmail] = useState('');
  const [currentFund,setCurrentFund] = useState('');
  const [addedFund,setAddedFund] = useState('');
  const [reducedFund,setReducedFund] = useState('');
  const [newFund,setNewFund] = useState('');
  const [checkFundStatus,setCheckFundStatus] = useState(false)
  const [fundValidate,setFundValidate] = useState(false)
    const reduceRadioChange = () => {
      
      setIsChecked(true);
      setReduces(true);
    };
    const addRadioChange = () => {
      setIsChecked(false);
      setReduces(false);
    };

     let token;
     const businessPartner = JSON.parse(window.localStorage.getItem('business-client'));
     token = businessPartner?.token
     const id = businessPartner?.user?._id;
     const customerIds = businessPartner?.user?.business_partner_id;
     const userIds = businessPartner?.userData._id


    const handleCloseReduceModal = () => {
        // setshowReduceModal(!showReduceModal);
        setStartCustomerClick(false)
        navigate("/partner/customer")
        setStartPartnerClick(false)
        setStartCustomerClick(false)
        setselectedPartnerValidate('default');
        setselectedCustomerValidate('default');
        setIsChecked(true);
        setReduces(true);
    };

      const handleCloseConfirmModal = () =>{
        setShowConfirmModal(false);
        setshowReduceModal(false);
        setStartPartnerClick(false)
        setStartCustomerClick(false)
        navigate("/partner/customer")
        setselectedPartnerValidate('default');
        setselectedCustomerValidate('default');
        setIsChecked(true);
        setReduces(true);
      }
      const {customerId} = location.state || {};
      useEffect(()=>{
        setshowReduceModal(true)
        customerId && handleCustomerSelectionModify(customerId)
       },[])

      useEffect(() => {
        sendPartner(customerIds)
      }, [customerIds,id])

      useEffect(() => {

        if (selectedCustomerValidate !== 'default' && fundValue.length !== 0) {
          setAllValidation(1);
        } else {
          setAllValidation(0);
        }
      }, [fundValue, selectedCustomerValidate]);

      const sendCustomer = (customerId) =>{
        const user = JSON.parse(window.localStorage.getItem("client"));
          if (user) {
            token = user.token;
          } else {
            token = null;
          }
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcustomer/${customerId}`, {
                headers: {
                  'Authorization' : `Bearer ${getNewPartnerToken()}`
                }
              
            }
            )
            .then((response) => {
              if(response.data.message == "Please Login"){
                localStorage.removeItem('business-client');
                navigate('/business-login');
                toast.error("Session Expired Please Login Again")
              }    
              const customerData = response.data.customer
              setCustomerName(customerData.first_name)
              setCustomerLastName(customerData.last_name)
              setCustomerEmail(customerData.email)
              setCustomerPhone(customerData.phone)
              setCustomerAmount(customerData.balance)
    
            })
            .catch((error) => console.error(`Error: ${error}`));
      };

      const sendPartner = (customerIds) => {
        // const apiUrl = `https://uat.backend.ncentiva.com/api/admin/customerpartner/${partnerId}`;
        setSendPartnerID(customerIds)
        
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
          token = user.token;
        } else {
          token = null;
        }
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/business-customer/${customerIds}`, {
              headers: {
                'Authorization' : `Bearer ${getNewPartnerToken()}`
              }
          }
          )
          .then((response) => {
            if(response.data.message == "Please Login"){
              localStorage.removeItem('business-client');
              navigate('/business-login');
              toast.error("Session Expired Please Login Again")
            }    
            const businessCustomer = response.data.customers
    
            setbusinessCustomer(businessCustomer);
            // setbusinessEnable(true);
    
          })
          .catch((error) => console.error(`Error: ${error}`));
      };
    

      const handleCustomerSelection = (event) => {
        const selectedCustomerId = event.target.value;
        setselectedCustomerValidate(selectedCustomerId);
        if(selectedCustomerId !== 'default'){
          sendCustomer(selectedCustomerId);
        }
        else{
          setCustomerName('');
          setCustomerLastName('');
          setCustomerEmail('');
          setCustomerPhone('');
          setCustomerAmount('');
        }
      };
      const handleCustomerSelectionModify = (value) => {
        const selectedCustomerId = value;
        setselectedCustomerValidate(selectedCustomerId);    
        sendCustomer(selectedCustomerId);
      };

      const CustomerClick = () => {
        setStartCustomerClick(true);
      };
      const handleFundChange = (event) => {
        const inputValue = event.target.value.replace(/[eE-]/g, '');
        if (inputValue.includes('e') || inputValue.includes('E')) {
          return; 
        }
        setFundValue(inputValue);
        setStartFundValidation(true);
      };

      const handleKeyDown = (event) => {
        // Prevent the user from typing "e"
        if (event.key === "e" || event.key === "E" || event.key === "-") {
          event.preventDefault();
        }
        setStartFundValidation(true);
      };

      const balanceSubmit = () => {
       if (!isSubmitting) {
            setIsSubmitting(true);
            const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/modifyfunds`;
            // Data to send in the POST request body
            const data = {
              business_partner: sendPartnerID,
              customer_email: customerEmail,
              reduce_funds: isChecked,
              funds: fundValue,
              loginUserId: userIds
            };
        
            const user = JSON.parse(window.localStorage.getItem("client"));
            if (user) {
              token = user.token;
            } else {
              token = null;
            }
        
            axios.post(apiUrl, data ,{
              headers: {
                'Authorization': `Bearer ${getNewPartnerToken()}`
              }})
              .then(response => {
                // Handle the API response here (if needed)
                if(response.data.customer){
                  toast.success(response.data.message);
                  setshowReduceModal(false);
                  setShowConfirmModal(false);
                  // getAllCustomers()
                  setIsChecked(true)
                  // window.location.reload();
                  if(response.data.message){
                    setTimeout(() => {
                      setIsSubmitting(false);
                     navigate("/partner/customer")
                    }, 1000); 
                  }
    
                }else{
                  // toast.warning(response.data.message);
                  setIsSubmitting(false);
                  
                }
              })
              .catch(error => {
                setIsSubmitting(false);
                toast.error("Failed to modify funds");
                console.error('API Error:', error);
              });
            }
    
          
        
      };

      const doubleConfirmation = () =>{
        if(allValidation !== 1 ){
          setStartPartnerClick(true);
          setStartCustomerClick(true);
          setStartFundValidation(true);
    
        }else if(fundValue.length === 0){
          toast.warning("Fund Value Can Not Empty")
          return false;
        }else if (parseFloat(fundValue) > parseFloat(customerAmount) && (isChecked)){
          return false;
        }else{
          setShowConfirmModal(true);
          setshowReduceModal(false);
          const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/confiramtion/modifyfunds`;
          // Data to send in the POST request body
          const data = {
            business_partner: sendPartnerID,
            customer_email: customerEmail,
            reduce_funds: isChecked,
            funds: fundValue,
            // payment_type:
          };
    
          const user = JSON.parse(window.localStorage.getItem("client"));
          if (user) {
            token = user.token;
          } else {
            token = null;
          }
    
          axios.post(apiUrl, data, {
            headers: {
              'Authorization': `Bearer ${getNewPartnerToken()}`
            }
          })
          .then((response)=>{
            const customerData = response.data.customer;
            setCusName(`${customerData.first_name} ${customerData.last_name}`);
            setCusEmail(customerData.email);
            setCurrentFund(response.data.currentFunds);
            setNewFund(response.data.modifiedFunds);
            setCheckFundStatus(response.data.deduct)
            setAddedFund(response.data.fund);
            setReducedFund(response.data.fund);
    
          })
          .catch((err)=>{
           console.log(err);
          })


        }

      }
    
    
  return (
   <>
   {/* <div className='p-3 p-lg-4 p-xl-5'>
  
   </div> */}
    
    
   <Modal
        show={showReduceModal}
        onHide={handleCloseReduceModal}
        backdrop="static"
        keyboard={false}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reduce_modal_body">
            <div className="row g-3">
                  <div className="col-12">
                    <label htmlFor="Customers-partner" className="form-label" >Customers</label>
                    <select name="" id="Customers-partner" className={`form-select ${selectedCustomerValidate === 'default' && startCustomerClick  ? 'is-invalid' : ''}`}  onChange={handleCustomerSelection} onClick={CustomerClick} value={selectedCustomerValidate} disabled={customerId? true : false}>
                      <option value="default">Select Customers</option>
                      {getbusinessCustomer.map((option, index) => (
                        <option key={index} value={option._id}>
                          {option.first_name} {option?.last_name} - {option.email}
                        </option>
                      ))}
                    </select>
                    {selectedCustomerValidate === 'default' && selectedPartnerValidate !== 'default' && startCustomerClick && (
                        <div className="invalid-feedback">Please select a valid Customer.</div>
                    )}
                    {selectedPartnerValidate === 'default' && startPartnerClick && (
                        <div className="invalid-feedback">Please select a Business partner first.</div>
                    )}
                  </div>
                {selectedCustomerValidate !== 'default' && <>
                  <div className="col-12">
                    <div className="my-2">
                      <label htmlFor="">Name: </label>
                      <span className="text-dark"> {customerName}  {customerLastName}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">Email: </label>
                      <span className="text-dark"> {customerEmail}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">phone Number: </label>
                      <span className="text-dark"> {customerPhone}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">Balance: </label>
                      <span className="text-dark"> {`$${customerAmount == "NaN" ? '0' : Number(customerAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row g-2">
                      <div className="col-auto">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="fund_change"
                            id="fund_change1"
                            defaultChecked
                            onClick={reduceRadioChange}
                          />
                          <label className="form-check-label" htmlFor="fund_change1">
                            Reduce funds
                          </label>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="fund_change"
                            id="fund_change2"
                            onClick={addRadioChange}
                          />
                          <label className="form-check-label" htmlFor="fund_change2">
                            Add funds
                          </label>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="form-group">
                          <input type="number" className={`form-control ${fundValue.length == 0 && startFundValidation && !fundValidate  ||  parseFloat(fundValue) > parseFloat(customerAmount) && startFundValidation && (reduces == true) ? 'is-invalid' : ''}`} value={fundValue} onChange={handleFundChange} onKeyDown={handleKeyDown} placeholder="Enter amount" style={{maxWidth: "100%", }}/>
                          {startFundValidation && <>
                            {fundValue.length > 0 && (
                              <div className="valid-feedback">Looks good!</div>
                            )}
                          {parseFloat(fundValue) > parseFloat(customerAmount) && (reduces == true)  &&(
                              <div className="invalid-feedback">Amount is more than Available Balance</div>
                            )}
                           {fundValue.length === 0 && !fundValidate && (
                              <div className="invalid-feedback">Please enter an amount.</div>
                            )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseReduceModal}>
            Close
          </Button>
          <Button variant="primary" disabled={isSubmitting} className="fullRounded newCustomButton px-4 py-2 fw-bold font12 btn btn-primary"  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }} onClick={doubleConfirmation}>{isSubmitting ? 'Update' : 'Update'}</Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showConfirmModal} 
      onHide={handleCloseConfirmModal} 
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      >
        <Modal.Header className='newModalHeaderColor' closeButton>
          <Modal.Title>Do you want to modify funds?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Customer Name :<span style={{marginLeft:"10px"}}>{cusName}</span></p>
          <p>Customer Email :<span style={{marginLeft:"10px"}}>{cusEmail}</span></p>
          <p>Current Funds :<span style={{marginLeft:"10px"}}>{`$${Number(currentFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
          {checkFundStatus ?
          <p>Reduced Funds :<span style={{marginLeft:"10px"}}>{`$${Number(reducedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
          :
          <p>Added Funds : <span style={{marginLeft:"10px"}}> {`$${Number(addedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>}
          <p>New Funds :<span style={{marginLeft:"10px"}}>{`$${Number(newFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary"className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseConfirmModal}>
            Decline
          </Button>
          <Button variant="primary" className="fullRounded newCustomButton px-4 py-2 fw-bold font12 btn btn-primary"  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }} onClick={balanceSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

   </>
  )
}

export default ModifyFund;