import {  useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {  toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import {BiPlusMedical} from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import { getNewPartnerToken, getNewToken } from "../config/cookie.config";

function ContactModal(props) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  // const { id } = useParams();
  const id=props?.id;
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [code, setCode] = useState("");
  const partner_id = id;
  const [phone,setPhone] = useState("");
  const [role,setRole] = useState("");
  const [errorPhone,setErrorPhone] = useState();
  // const navigate = useNavigate();

  let name;
  let user;
  let userId
  if(props.admin !== true){
     user = JSON.parse(window.localStorage.getItem("business-client"));
     userId = user?.userData?._id;
  }else{
    user = JSON.parse(window.localStorage.getItem("client"));
     userId = user?.user?._id;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [error,setError] = useState(false);
  const [delay,setDelay] = useState(false);
  const [phoneError,setPhoneError] = useState();
  const [isEmail, setIsEmail] = useState(false);

  const formikInitialValue = {
    role,
    first_name,
    last_name,
    code,
    phone,
    partner_id,
    // checkEmail,
  };
   
  
  function isValidEmail(email) {
    return /^\S+@\S+\.\S{2,}$/.test(email);
    // return /\S+@\S+\.\S+/.test(email);
  }
  
  const handleChangeEmail = event => {
    setIsEmail(false);
    if (!isValidEmail(event.target.value) || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  
    setEmail(event.target.value);
  };


  const formSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Name cannot be empty"),
      last_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
       role: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for User type")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("User type cannot be empty"),
      phone: Yup.string()
      // .min(14, "Minimum 10 digits required for Phone number")
      // .max(14, "Maximum 10 digits allowed for Phone number")
      .required("Phone number cannot be empty"),
    checkEmail: Yup.boolean(),
    code: Yup.string()
      .min(1, "minimum 1 digit require")
      .required('code cannot be empty'),
  });


 let token;
  const handleSubmitForm = async (data) => {
    const {
      role,
      first_name,
      last_name,
      code,
      phone,
      partner_id,
    } = data;
    const name = first_name + ' ' + last_name;
    data.email=email;
    console.table({
      role,
      name,
      code,
      phone,
      email,
      partner_id,
    });
    try {
      setIsLoading(true);
      let para;
      if(props.admin === true){
        para="admin"
        const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        // token = user.token;
        token = getNewToken();
      } else {
        token = null;
      }
      }else{
        para = "partner"
        const user = JSON.parse(window.localStorage.getItem("business-client"));
        if (user) {
          // token = user.token;
          token = getNewPartnerToken();
        } else {
          token = null;
        }
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/user/userbyadminandbusiness/create`,
        {
          role,
          name,
          code,
          phone,
          email,
          partner_id,
          // loginUserId: userId
        }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      },
      );
      if (data.status === "success") {
        toast.success(data.message);
        setShow(false);
        // window.location.reload();
        props.admin ? props.getAllUser(props.pageNumbers) : props.getAllUsers(props.pageNumber);
      }else if(data.message === "Email Already Exists"){
        setIsEmail(true);
      } 
    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err.response.data.message);
    }
  };
  function phoneFormat(input) {
    input = input.replace(/\D/g, "");
    input = input.trim()
    input = input.substring(0, 10);

    var size = input.length;
    if (size === 0) {
      input = input;
    }
     else if (size < 4) {
      input = "(" + input;
    } 
    else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } 
    
    else if(size <= 11)
    input= "("+input.substring(0, 3)+") "+input.substring(3, 6)+"-" +input.substring(6, 11);
    if (input?.length > 10 && input?.length <= 14){
      setErrorPhone(false);
    }else{
      setErrorPhone(true);
    }
    return input;
  }
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const char = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };


 const phoneCode = [
   { value: "+1", label: "+1 USA" },
   { value: "+7", label: "+7 RUS" },
   { value: "+20", label: "+20 EGY" },
   { value: "+27", label: "+27 ZAF" },
   { value: "+30", label: "+30 GRC" },
   { value: "+31", label: "+31 NLD" },
   { value: "+32", label: "+32 BEL" },
   { value: "+33", label: "+33 FRA" },
   { value: "+34", label: "+34 ESP" },
   { value: "+36", label: "+36 HUN" },
   { value: "+39", label: "+39 ITA" },
   { value: "+40", label: "+40 ROU" },
   { value: "+41", label: "+41 SWZ" },
   { value: "+43", label: "+43 AUT" },
   { value: "+44", label: "+44 GBR" },
   { value: "+45", label: "+45 DNK" },
   { value: "+46", label: "+46 SWE" },
   { value: "+47", label: "+47 NOR" },
   { value: "+48", label: "+48 POL" },
   { value: "+49", label: "+49 DEU" },
   { value: "+51", label: "+51 PER" },
   { value: "+52", label: "+52 MEX" },
   { value: "+53", label: "+53 CUB" },
   { value: "+54", label: "+54 ARG" },
   { value: "+55", label: "+55 BRA" },
   { value: "+56", label: "+56 CHL" },
   { value: "+57", label: "+57 COL" },
   { value: "+58", label: "+58 VEN" },
   { value: "+60", label: "+60 MYS" },
   { value: "+61", label: "+61 AUS" },
   { value: "+62", label: "+62 IDN" },
   { value: "+63", label: "+63 PHL" },
   { value: "+64", label: "+64 NZL" },
   { value: "+65", label: "+65 SGP" },
   { value: "+66", label: "+66 THA" },
   { value: "+81", label: "+81 JPN" },
   { value: "+82", label: "+82 KOR" },
   { value: "+84", label: "+84 VNM" },
   { value: "+86", label: "+86 CHN" },
   { value: "+90", label: "+90 TUR" },
   { value: "+91", label: "+91 IND" },
   { value: "+92", label: "+92 PAK" },
   { value: "+93", label: "+93 AFG" },
   { value: "+94", label: "+94 LKA" },
   { value: "+95", label: "+95 MMR" },
   { value: "+98", label: "+98 IRN" },
   { value: "+211", label: "+211 SSD" },
   { value: "+212", label: "+212 MAR" },
   { value: "+213", label: "+213 DZA" },
   { value: "+216", label: "+216 TUN" },
   { value: "+218", label: "+218 LBY" },
 ];

  function nameFormat(input) {
    if (input === " ") {
      input = input.replace(/\s/g, "");
    }
    return input;
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <Button variant="primary"
                type="button"
                
                className="fullRounded newCustomButton fw-bold font14"
                style={{width:"150.5px",backgroundColor: 'var(--newOrange)',
                  borderColor: 'var(--newOrange)',height:"36px"}} onClick={handleShow}>
      <span className="me-2">
                  {/* <img
                    src="/assets/images/plus.svg"
                    className="img-fluid"
                    alt=""
                  /> */}
                   <BiPlusMedical />
                </span>
                {props.admin ? <span>Add User</span> : <span>Add User</span>}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
        {props.admin ? <Modal.Title>User Information</Modal.Title> : <Modal.Title>User Information</Modal.Title>}
          
        </Modal.Header>
        <Modal.Body>
        <Container fluid className="mt-4">
          <Row>
            <Col lg={12} md={12}>
              <Formik
                enableReinitialize={true}
                initialValues={formikInitialValue}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setDelay(true);
                  setTimeout(()=>{
                  setDelay(false);
                  },1500)
                  if(email.length<5){
                    setError(true);
                  }else{
                  //setStillSubmitting(true);
                  setTimeout(() => {
                    handleSubmitForm(values);
                    setSubmitting(false);
                  }, 400);
                }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting /* and other goodies */,
                }) => (
                  <Form
                    className="bg-white p-1 rounded containerShadow"
                    onSubmit={handleSubmit}
                  >
                    <Row>
                     
                      <Col md={6}>
                        <Form.Group className="mb-3">
                         {props.admin ?  <Form.Label className="font14 text-dark fw-medium">
                          User Type{" "}
                          <span className="text-danger">*</span>
                          </Form.Label> : <Form.Label className="font14 text-dark fw-medium">
                          User Type{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>}
                          {props.admin ? 
                          <Field as='select' className="font14 form-select" name='role' value={(values.role)} onChange={handleChange}
          onBlur={handleBlur} style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
          isValid={touched.role && !errors.role}
          isInvalid={!!errors.role} type='text'>
          <option value="" label="User type" disabled />
          <option value="Primary" label="Primary" />
          <option value="Management" label="Management" />
          <option value="Marketing" label="Marketing" />
          <option value="Technical" label="Technical" />
          <option value="Acounting" label="Acounting" />
          <option value="Customer Service" label="Customer Service" />
        </Field>:
        <Field as='select' className="font14 form-select" name='role' value={(values.role)} onChange={handleChange}
        onBlur={handleBlur} style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
        isValid={touched.role && !errors.role}
        isInvalid={!!errors.role} type='text'>
        <option value="" label="User type" disabled />
        <option value="Primary" label="Primary" />
        <option value="Management" label="Management" />
        <option value="Marketing" label="Marketing" />
        <option value="Technical" label="Technical" />
        <option value="Acounting" label="Acounting" />
        <option value="Customer Service" label="Customer Service" />
      </Field>}
                          {errors.role && touched.role ? (
                            <div className="text-danger font14">
                              {errors.role}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText1">
                          <Form.Label className="font14 text-dark fw-medium">
                           First Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            name="first_name"
                            className="font14"
                            value={nameFormat(values.first_name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter First Name"
                            isValid={touched.first_name && !errors.first_name}
                            isInvalid={!!errors.first_name}
                          />
                          {errors.first_name && touched.first_name ? (
                            <div className="text-danger font14">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font14 text-dark fw-medium">
                           Last Name
                          </Form.Label>
                          <Form.Control
                            name="last_name"
                            className="font14"
                            value={nameFormat(values.last_name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Last Name"
                            isValid={touched.last_name && !errors.last_name}
                            isInvalid={!!errors.last_name}
                          />
                          {errors.last_name && touched.last_name ? (
                            <div className="text-danger font14">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-3 mb-lg-0">
                          <Form.Label className="font14 text-dark fw-medium">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="setect_code">
                                <Select
                                  className={`font12 form-select${
                                    values.code.length > 10 ? "sdd" : "jk"
                                  }`}
                                  type="text"
                                  name="code"
                                  value={phoneCode.find(
                                    (option) => option.value === values.code
                                  )}
                                  onChange={(selectedOption) =>
                                    handleChange({
                                      target: {
                                        name: "code",
                                        value: selectedOption.value,
                                      },
                                    })
                                  }
                                  onBlur={handleBlur}
                                  options={phoneCode}
                                  isSearchable
                                  placeholder="Code"
                                />
                              </div>
                              {/* <Field as='select' className="font14 form-select" name='code' value={(values.code)} onChange={handleChange}
                              onBlur={handleBlur} style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                              isValid={touched.code && !errors.code}
                              isInvalid={!!errors.code} type='text'>
                              <option value="" label="Code" disabled/>
                              <option value="+1" label="+1 USA" />
                              <option value="+7" label="+7 RUS" />
                              <option value="+20" label="+20 EGY" />
                              <option value="+27" label="+27 ZAF" />
                              <option value="+30" label="+30 GRC" />
                              <option value="+31" label="+31 NLD" />
                              <option value="+32" label="+32 BEL" />
                              <option value="+33" label="+33 FRA" />
                              <option value="+34" label="+34 ESP" />
                              <option value="+36" label="+36 HUN" />
                              <option value="+39" label="+39 ITA" />
                              <option value="+40" label="+40 ROU" />
                              <option value="+41" label="+41 SWZ" />
                              <option value="+43" label="+43 AUT" />
                              <option value="+44" label="+44 GBR" />
                              <option value="+45" label="+45 DNK" />
                              <option value="+46" label="+46 SWE" />
                              <option value="+47" label="+47 NOR" />
                              <option value="+48" label="+48 POL" />
                              <option value="+49" label="+49 DEU" />
                              <option value="+51" label="+51 PER" />
                              <option value="+52" label="+52 MEX" />
                              <option value="+53" label="+53 CUB" />
                              <option value="+54" label="+54 ARG" />
                              <option value="+55" label="+55 BRA" />
                              <option value="+56" label="+56 CHL" />
                              <option value="+57" label="+57 COL" />
                              <option value="+58" label="+58 VEN" />
                              <option value="+60" label="+60 MYS" />
                              <option value="+61" label="+61 AUS" />
                              <option value="+62" label="+62 IDN" />
                              <option value="+63" label="+63 PHL" />
                              <option value="+64" label="+64 NZL" />
                              <option value="+65" label="+65 SGP" />
                              <option value="+66" label="+66 THA" />
                              <option value="+81" label="+81 JPN" />
                              <option value="+82" label="+82 KOR" />
                              <option value="+84" label="+84 VNM" />
                              <option value="+86" label="+86 CHN" />
                              <option value="+90" label="+90 TUR" />
                              <option value="+91" label="+91 IND" />
                              <option value="+92" label="+92 PAK" />
                              <option value="+93" label="+93 AFG" />
                              <option value="+94" label="+94 LKA " />
                              <option value="+95" label="+95 MMR" />
                              <option value="+98" label="+98 IRN" />
                              <option value="+211" label="+211 SSD" />
                              <option value="+212" label="+212 MAR" />
                              <option value="+213" label="+213 DZA" />
                              <option value="+216" label="+216 TUN" />
                              <option value="+218" label="+218 LBY" />
                            </Field> */}
                         
                              &nbsp;

                              {values.code === '' ?
                                <Form.Control

                                  name="phone"
                                  maxLength={14}
                                  value={phoneFormat(values.phone)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                 
                                  placeholder="Enter Phone"
                                  className="font14"
                                  onKeyPress={handleKeyPress}
                                  isValid={touched.phone && !errors.phone}
                                  isInvalid={!!errors.phone}
                                  disabled
                                />
                                :

                                <Form.Control
                                  name="phone"
                                  maxLength={14}
                                  value={phoneFormat(values.phone)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  
                                  isValid={touched.phone && !errorPhone}
                                  isInvalid={errorPhone}
                                  placeholder="Enter Phone"
                                  className="font14"
                                  onKeyPress={handleKeyPress}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    const pastedText = e.clipboardData.getData('text/plain');
                                    const formattedPhoneNumber = phoneFormat(pastedText);
                                    handleChange({ target: { name: 'phone', value: formattedPhoneNumber } });
                                  }}
                                  required
                                />

                              }

                          </div>
                          <div className="d-flex">
                            {values.phone === '' ? '' : (errors.code && touched.code ? (
                              <div className="text-danger font14">
                                {errors.code}
                              </div>
                            ) : null)
                            }

                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {phoneError && touched.phone ? (
                              <div className="text-danger font14">
                                {errors.phone}
                              </div>
                            ) : null}

                          </div>
                        </Form.Group>
                      </Col>
                       <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="font14 text-dark fw-medium">
                            Email Address <span className="text-danger">*</span>
                          </Form.Label>
                          {/* {partner_id ? (
                            <Form.Control
                              className="font14"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter Email Address"
                              disabled
                            />
                           ) : (  */}
                            <Form.Control
                              className="font12"
                              type="email"
                              name="email"
                              value={email}
                              onChange={handleChangeEmail}
                              onBlur={(e) => {
                                handleBlur(e);
                                if (errors.email) {
                                  setEmail({
                                    ...values,
                                    // checkEmail: true,
                                  })
                                }
                              }
                              }
                              placeholder="Enter Email Address"
                              isValid={touched.email && !error}
                              isInvalid={error || isEmail}
                            />
                            {/* )}   */}
                          {error ? <div className="text-danger font14">Invalid email</div> :""}
                          {isEmail === true ? <div className="text-danger font14">Email Already Exists</div>: ""}
                        </Form.Group>
                      </Col>
                      
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        {delay?<Button variant="primary fullRounded newCustomButton fw-bold font14"  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }}>
                          <span className="me-2">
                            {/* <img
                            src="/assets/images/plus.svg"
                            className="img-fluid"
                            alt=""
                            /> */}
                             <BiPlusMedical />
                          </span>
                          <span>Create User Info</span>
                        </Button>:<Button type="submit" variant="primary fullRounded newCustomButton fw-bold font14"  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }}>
                          <span className="me-2">
                            {/* <img
                            src="/assets/images/plus.svg"
                            className="img-fluid"
                            alt=""
                            /> */}
                             <BiPlusMedical />
                          </span>
                          <span>Create User Info</span>
                        </Button>}
                      </Col>
                    </Row>
                  </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      <Modal.Footer>     
    </Modal.Footer>
  </Modal>
 </>
);
}

export default ContactModal;